'use strict';
var customProductImages = require('./components/customProductImages');

/**
 * Align Carousel Tile elements height
* @param {Object} carouselContainer - DOM element
 */
function alignCarouselTile(carouselContainer) {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };
    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;
    var $tileBodyProductBadge;
    carouselContainer.forEach(el => {
        $tileBodyProductName = $(el).find('.tile-body-productname');
        $tileBodyPrice = $(el).find('.tile-body-content-top .price');
        $tileBodyPromo = $(el).find('.tile-body-promo-callout-plp');
        $tileBodyMoreColor = $(el).find('.tile-body-more-color');
        $tileBodyProductBadge = $(el).find('.tile-body-product-badge');
        $tileBodyProductName.unSyncHeight().syncHeight();
        $tileBodyPrice.unSyncHeight().syncHeight();
        $tileBodyPromo.unSyncHeight().syncHeight();
        $tileBodyMoreColor.unSyncHeight().syncHeight();
        $tileBodyProductBadge.unSyncHeight().syncHeight();
    });
}

/**
 * Find and Fix Carousel Tile elements height
 */
function fixCarouselContentTiles() {
    var pdSlickCarousel = document.querySelectorAll('div.carousel-inner');
    if (pdSlickCarousel.length > 0) {
        alignCarouselTile(pdSlickCarousel);
    }
}

/**
 *Function used to initialize slick carousel in Page designer.
 */
function initSlick() {
    var sliderInterval = setInterval(function () {
        if ($('.experience-commerce_layouts-slickCarousel .carousel-inner').html() && $('.experience-commerce_layouts-slickCarousel .carousel-inner').html().length > 0) {
            $('.slick-arrow-slot-1').empty();
            $('.experience-commerce_layouts-slickCarousel .carousel-inner').slick({
                infinite: true,
                arrows: true,
                appendArrows: $('.slick-arrow-slot-1'),
                prevArrow: '<i class="icon icon-icon-chevron-left slick-arrows slick-prev"></i>',
                nextArrow: '<i class="icon icon-icon-chevron-right slick-arrows slick-next"></i>',
                variableWidth: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3
                    }
                }]
            });
            $('.slick-carousel-slides-hide').removeClass('d-none');
            $('.slick-carousel-slides-hide').addClass('d-block');
            clearInterval(sliderInterval);
            customProductImages.handleProductSwapMissingImages();
            fixCarouselContentTiles();
        }
    }, 1000);
}

$(document).ready(function () {
    initSlick();
});
